<script setup lang="ts">
import { computed } from "vue"
import { type Order } from "@/shop/pages/checkout/CheckoutConfirmationPage.vue"

const props = withDefaults(
    defineProps<{
        order?: Order
    }>(),
    {
        order: undefined,
    },
)

const essences = computed(() => {
    return props.order?.items?.filter(item => !item.isSpecialType && item.article?.type == "essence")
})

const cannabis = computed(() => {
    return props.order?.items?.filter(item => !item.isSpecialType && item.article?.type == "cannabis")
})
</script>

<template>
    <h2 class="text-dark">Wichtige Hinweise zum Rezept</h2>
    <div>
        Bitte achte darauf, dass auf deinem Rezept deine gewünschte Sorte <b>exakt</b> bezeichnet wird. Folgende Angaben
        zu Sorte und Mengen <b>müssen</b> auf deinem Rezept stehen:
        <div v-if="cannabis?.length" class="my-2">
            <b>Cannabisblüten</b>
            <ul>
                <li v-for="item in cannabis" :key="item.id">
                    <b>{{ item.article?.publicNamePrimary }}</b> <i>({{ item.article?.publicNameSecondary }})*</i>
                    {{ item.amount }}g
                </li>
            </ul>
        </div>
        <div v-if="essences?.length" class="my-2">
            <b>Cannabisextrakte</b>
            <ul>
                <li v-for="item in essences" :key="item.id">
                    <b>{{ item.article?.publicNamePrimary }}</b> {{ item.amount }}g
                </li>
            </ul>
        </div>
    </div>
    <span v-if="cannabis?.length" class="text-muted small">*optional</span>
</template>
