<script setup lang="ts">
import { assetLink } from "@/common/util"
import type { Article } from "@/model/backend/article"
import ArticlesSidebar from "@/shop/components/ArticlesSidebar.vue"
import { useShopAppConfig } from "@/shop/composables"
import { useAddToCardNotice } from "@/shop/composables/useAddToCardNotice"
import { useShopDialogs } from "@/shop/composables/useShopDialogs"
import { useCartStore } from "@/shop/store/useCartStore"
import { useTooManyOrdersWarningStore } from "@/shop/store/useTooManyOrdersWarning"
import { $articleType, $articleUnit } from "@/shop/util"
import { $currency } from "@/vf"
import { onMounted, onUnmounted, ref } from "vue"
import { ImageZoom } from "./image-zoom"
import ArticlePageTerpeneInfoButton from "@/shop/pages/article/ArticlePageTerpeneInfoButton.vue"
import ArticlePageHelpButton from "./ArticlePageHelpButton.vue"
import TymcTamcExplanation from "./TymcTamcExplanation.vue"
/*─────────────────────────────────────┐
│   props                              │
└─────────────────────────────────────*/
const props = withDefaults(
    defineProps<{
        id?: string
        urlId?: string
        slug?: string
    }>(),
    {
        id: undefined,
        urlId: undefined,
        slug: undefined,
    },
)

/*─────────────────────────────────────┐
│   functions                          │
└─────────────────────────────────────*/
function setupImageZoom() {
    if (!imageContainer.value) {
        return
    }
    const rect = imageContainer.value.getBoundingClientRect()
    const size = rect.width
    imageContainer.value.style.height = size + "px"
    imageZoom.value = ImageZoom(imageContainer.value, {
        img: assetLink("/uploads/media/" + mainArticle.shopImages?.[selectedImageIndex.value].imageName),
        fillContainer: false,
        width: rect.width,
        height: size,
    })
}

function destroyImageZoom() {
    if (imageZoom.value) {
        imageZoom.value.kill()
    }
}

const addToCartNotice = useAddToCardNotice()

async function addToCart(): Promise<void> {
    if (amount.value == 0) {
        missingAmount.value = true
        return
    }

    missingAmount.value = false

    if (!(await alreadyInCartNotice(article.value))) {
        // article is already in cart and user decided to not add more
        return
    }

    if (await tooManyOrdersWarning.noWarningOrDoAnyways()) {
        cartStore.addToCart(article.value, amount.value)
        addedSuccessful.value = true
    }

    // notice to customer, that the article has been added to cart but is not reserved yet
    addToCartNotice.open()

    setTimeout(() => (addedSuccessful.value = false), 2000)
}

async function alreadyInCartNotice(article: Article) {
    const item = cartStore.getCartItemForArticle(article)

    if (!item) {
        // article is not in cart yet
        return true
    }

    return await dialogs.confirm(
        "Artikel bereits im Warenkorb",
        `Der Artikel befindet sich bereits ${item.amount} mal in deinem Warenkorb. Möchtest du die Anzahl erhöhen?`,
    )
}

function selectImage(index: number) {
    selectedImageIndex.value = index
    destroyImageZoom()
    setupImageZoom()
}

function getDryingLossColor(dryingLoss: number) {
    if (dryingLoss >= 9) {
        return "text-success"
    }
    if (dryingLoss < 9 && dryingLoss >= 6) {
        return "text-warning"
    }
    return "text-danger"
}

/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const { appConfig } = useShopAppConfig()
const tooManyOrdersWarning = useTooManyOrdersWarningStore()
const cartStore = useCartStore()
const dialogs = useShopDialogs()

/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
// routes beforeEnter guard ensures that this is always defined
const mainArticle = appConfig.value.articles.find(i => (props.id ? i.id == props.id : i.urlId == props.urlId))!
const article = ref(mainArticle.isVariationArticle ? mainArticle.variations[0].childArticle : mainArticle)

const amount = ref<number>(article.value.type === "cannabis" ? 5 : 1)
const addedSuccessful = ref<any>(false)
const missingAmount = ref<any>(false)
const selectedImageIndex = ref<any>(0)
const imageContainer = ref<HTMLElement>()
const imageZoom = ref<any>(null)
/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/
onMounted(() => setupImageZoom())
onMounted(() => {
    window.scrollTo(0, 0)
})
onUnmounted(() => destroyImageZoom())
console.log(mainArticle)
</script>

<template>
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-xl-2 d-md-block d-none">
                <ArticlesSidebar />
            </div>
            <div class="col-md-9 col-xl-10">
                <div class="breadcrumb mt-3">
                    <RouterLink class="breadcrumb-item" :to="{ name: 'home' }">Home</RouterLink>
                    <RouterLink class="breadcrumb-item" :to="{ name: 'articles' }">Sortiment</RouterLink>
                    <div class="breadcrumb-item">{{ $articleType(mainArticle.type!) }}</div>
                    <div class="breadcrumb-item">{{ mainArticle.publicNameSecondary }}</div>
                </div>

                <div class="row mt-5">
                    <div class="col-md-5">
                        <img
                            v-if="(mainArticle.shopImages?.length ?? 0) == 0"
                            src="@/shop/assets/placeholder.jpg"
                            class="img-fluid"
                        />

                        <div v-if="(mainArticle.shopImages?.length ?? 0) > 0" class="article-main-image">
                            <div ref="imageContainer"></div>
                            <div v-if="['cannabis', 'essence'].includes(article.type!)" class="mx-1">
                                <template v-if="mainArticle.shopImages[selectedImageIndex].isBatchImage">
                                    Bild der aktuellen Charge
                                </template>
                                <template v-else>Beispielabbildung einer anderen Charge</template>
                            </div>
                            <!--                            <img-->
                            <!--                                src.bind="app.assetLink('/uploads/media/' + article.images[selectedImageIndex].imageName)"-->
                            <!--                                alt=""-->
                            <!--                                if.bind="article.imageName" class="img-fluid">-->
                        </div>

                        <div v-if="(mainArticle.shopImages?.length ?? 0) > 1" class="article-images">
                            <div
                                v-for="(image, $index) in mainArticle.shopImages"
                                :key="image.id"
                                class="article-image"
                                :class="{ active: $index === selectedImageIndex }"
                                @click="selectImage($index)"
                            >
                                <img class="img-fluid" :src="assetLink('/uploads/media/' + image.imageName)" />
                            </div>
                        </div>

                        <RouterLink class="text-muted mt-5 d-inline-block" :to="{ name: 'articles' }">
                            <i class="fa fa-fw fa-chevron-left"></i>
                            Zurück zur Übersicht
                        </RouterLink>
                    </div>
                    <div class="col-md-7">
                        <h2>{{ mainArticle.publicNamePrimary }}</h2>
                        <h1>{{ mainArticle.publicNameSecondary }}</h1>

                        <div class="row">
                            <div class="col-md-6">
                                <div v-if="article.latestBatch?.thc">THC: {{ article.latestBatch?.thc }} %*</div>
                                <div v-if="article.latestBatch?.cbd">CBD: {{ article.latestBatch?.cbd }} %*</div>
                                <div v-if="article.latestBatch?.tymc">
                                    TYMC: {{ article.latestBatch?.tymc.replace("<", "≤") }} KBE/g
                                    <ArticlePageHelpButton>
                                        <TymcTamcExplanation />
                                    </ArticlePageHelpButton>
                                </div>
                                <div v-if="article.latestBatch?.tamc">
                                    TAMC: {{ article.latestBatch?.tamc.replace("<", "≤") }} KBE/g
                                    <ArticlePageHelpButton>
                                        <TymcTamcExplanation />
                                    </ArticlePageHelpButton>
                                </div>
                                <div v-if="article.latestBatch?.dryingLoss">
                                    Trocknungsverlust:
                                    <span :class="getDryingLossColor(parseFloat(article.latestBatch?.dryingLoss))">
                                        {{ article.latestBatch?.dryingLoss }} %</span
                                    >
                                    <ArticlePageHelpButton>
                                        Der Trocknungsverlust gibt an wie viel Masse bis zur vollständigen Trocknung
                                        noch verloren gehen kann. Temperatur und Druck während der Trocknung werden so
                                        gewählt, dass diese knapp über dem Siedepunkt von Wasser liegen. Flüchtige
                                        Substanzen mit einem niedrigeren Siedepunkt als Wasser werden also auch erfasst.
                                        Dennoch liefert der Wert einen Hinweis auf die Restfeuchte. Je geringer der
                                        ausgewiesene Trocknungsverlust, desto trockener ist das Produkt bereits. Bei
                                        höheren Werten ist das Produkt feuchter. Mehr als 12% Trocknungsverlust dürfen
                                        zur Freigabe nicht erreicht werden.
                                    </ArticlePageHelpButton>
                                </div>
                                <div v-if="article.brand">Marke: {{ article.brand.name }}</div>
                                <div v-if="article.type === 'cannabis' && article.strain">
                                    Strain: {{ article.strain }}
                                </div>
                                <div v-if="article.latestBatch?.pzn">PZN: {{ article.latestBatch?.pzn }}*</div>
                                <i class="small text-muted">* Werte der aktuellen Charge</i>
                            </div>
                            <div class="col-md-6">
                                <div v-if="article.type === 'cannabis'">
                                    <img src="@/shop/assets/cannabis.svg" style="width: 1em; height: 1em" />
                                    Genetik: {{ article.species }}
                                </div>
                                <div
                                    v-if="['cannabis', 'essence'].includes(article.type!) && article.packageAmount !== 1"
                                >
                                    <img src="@/shop/assets/bundle.svg" style="width: 1em; height: 1em" />
                                    Gebinde: {{ article.packageAmount }} {{ article.unit }}
                                </div>
                                <div v-if="article.countryOfOrigin">
                                    <i class="fas fa-earth-europe"></i>
                                    Herkunftsland: {{ article.countryOfOrigin }}
                                </div>
                                <div>
                                    <div
                                        style="width: 1rem; height: 1rem; border-radius: 50%"
                                        :class="(article.inStock ? 'bg-success' : 'bg-danger') + ' d-inline-block'"
                                    ></div>
                                    {{ article.inStock ? "Lieferbar" : "Nicht Lieferbar" }}

                                    <div v-if="!article.inStock" class="font-weight-bold">
                                        {{ article.notInStockText || "Liefertermin unbekannt" }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="article.isSellOffArticle" class="mt-1">
                            Dieses Produkt verlässt unser Lagersortiment!
                        </div>

                        <div v-if="appConfig.user && article.type === 'cannabis'" class="mt-3">
                            <div v-if="article.crossing"><b>Kreuzung:</b> {{ article.crossing }}</div>

                            <div v-if="article.cultivationFacility">
                                <b>Anbaubetrieb:</b> {{ article.cultivationFacility }}
                            </div>

                            <div v-if="article.cultivationMethod"><b>Anbauart:</b> {{ article.cultivationMethod }}</div>

                            <div v-if="article.irradiationMethod">
                                <b>Bestrahlungsmethode:</b> {{ article.irradiationMethod }}
                            </div>

                            <div v-if="article.latestBatch?.releaseSpecification">
                                <b>Freigabespezifikation:</b>
                                {{ article.latestBatch.releaseSpecification }}*
                            </div>

                            <div v-if="article.terpeneProfile">
                                <b>Die dominanten Terpene sind:</b>
                                <ul>
                                    <li v-for="(terpene, index) in article.terpeneProfile" :key="index">
                                        {{ terpene }}
                                        <ArticlePageTerpeneInfoButton
                                            v-if="
                                                appConfig.terpeneDescriptions[terpene].occurrence ||
                                                appConfig.terpeneDescriptions[terpene].description
                                            "
                                            :terpene="appConfig.terpeneDescriptions[terpene]"
                                        />
                                    </li>
                                </ul>
                            </div>
                            <div v-else>Es liegen keine Angaben zum Terpenprofil vor.</div>
                        </div>

                        <p v-if="mainArticle.text" class="mb-3 mt-5" v-html="mainArticle.text"></p>

                        <!--                        <div class="d-flex my-3" if.bind="article.price">-->
                        <!--                            <div class="article-price mr-4 my-auto">-->
                        <!--                                ${article.price | currency}-->
                        <!--                            </div>-->
                        <!--                            <div class="font-italic text-muted my-auto">-->
                        <!--                                pro ${article.unit | articleUnit} inkl. gesetzlicher MwSt. zzgl. Versand-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        -->

                        <div v-if="article.showLowStockWarning" class="alert alert-warning mt-5">
                            <b>Achtung:</b>
                            nur noch geringe Menge auf Lager
                        </div>

                        <div class="mt-5"></div>

                        <div v-if="mainArticle.isVariationArticle" class="row">
                            <div class="col-md-4">
                                <b>{{ mainArticle.variationLabel }}</b>
                            </div>
                            <div class="col-md-4">
                                <select v-model="article" class="form-control custom-select">
                                    <option
                                        v-for="variation in mainArticle.variations"
                                        :key="variation.id"
                                        :value="variation.childArticle"
                                    >
                                        {{ variation.name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div v-if="article.allow && article.inStock">
                            <div class="py-2 row align-items-center">
                                <div class="col-md-4">
                                    <b>Menge</b>
                                </div>
                                <div class="col-md-4 col-5 mt-2 mt-md-0">
                                    <select v-model="amount" class="form-control custom-select">
                                        <template v-for="i in 200" :key="i">
                                            <option v-if="article.type !== 'cannabis' || i >= 5" :value="i">
                                                {{ article.packageAmount === 1 ? i : i + "x " + article.packageAmount }}
                                                {{ article.unit }}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                                <div class="col-md-4 col-7 text-right mt-2 mt-md-0">
                                    <div class="article-price">
                                        {{ $currency(article.price * amount) }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="article.price && article.inStock" class="text-muted small">
                            {{ $currency(article.price / (article.packageAmount ?? 1)) }} /
                            {{ $articleUnit(article.unit!) }}
                            inkl MwSt. zggl. Versand
                        </div>

                        <div class="mb-5"></div>

                        <div v-if="article.inStock" class="d-flex align-items-center">
                            <button v-if="article.allow" class="btn btn-primary" @click="addToCart()">
                                In den Warenkorb
                            </button>
                            <span v-show="missingAmount" class="pl-3 text-primary">Bitte wähle eine Anzahl aus!</span>
                            <span v-show="addedSuccessful" class="pl-3 text-primary">Artikel hinzugefügt!</span>
                        </div>
                    </div>

                    <div v-if="article.requiresPrescription">
                        <hr />

                        <p class="text-muted small px-3">
                            Angaben zum Strain, Wirkstoffgehalt und genetischer Zuordnung (Sativa Dominanz, Indica
                            Dominanz, Hybrid Balance) beziehen sich auf die Angaben des Herstellers und erfolgen ohne
                            Gewähr. Da es sich um ein Naturprodukt handelt, können die Werte auch innerhalb einer Charge
                            schwanken. Dieser Tatsache wurde bei Chargenfreigabe Sorge getragen. Die Bilder können auch
                            aus älteren Chargen des Produktes stammen.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
