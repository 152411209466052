<script setup lang="ts">
import {
    type EntityEvent,
    type FormErrors,
    type FormModel,
    normalizeModuleConfiguration,
    useLoadEntity,
    VfForm,
    VfFormGroup,
    VfFormRendered,
    VfFormRow,
} from "@/vf"
import { computed, nextTick, ref, type Ref } from "vue"
import Cleave from "vue-cleave-component"
/*─────────────────────────────────────┐
│   props                              │
└─────────────────────────────────────*/

type StepType =
    | "payType"
    | "prescriptionTransmission"
    | "privateCard"
    | "uploadPrivateCard"
    | "coPaymentExemption"
    | "uploadCoPaymentExemption"
    | "uploads"
    | "infos"

type MultipleChoiceOption<ValueT> = {
    name: string
    description: string
    value: ValueT
}

/*─────────────────────────────────────┐
│   functions                          │
└─────────────────────────────────────*/
function elementInvalid(name: string) {
    invalidElements.value[name] = true
    return true
}

function elementChanged(name: string, event: Event) {
    invalidElements.value = {}
    formErrors.value[name] = []

    const files = (event.target as HTMLInputElement).files

    formModel.value[name] = files?.[0] ?? undefined

    // console.log(formModel.value)
}

type WizardOptions = typeof wizardOptions

function wizardOptionByValue<K extends keyof WizardOptions, V extends WizardOptions[K][I]["value"], I extends number>(
    key: K,
    value: V,
): WizardOptions[K][I] {
    const options = [...wizardOptions[key]] // unpacking is somehow required for typescript to understand this...?

    return options.find(o => o.value === value)!
}

function selectPayType(payType: string) {
    formModel.value.payType = payType
    nextStep()
}

function selectHasPrivateCard(hasCard: boolean) {
    values.value.hasPrivateCard = hasCard
    nextStep()
}

function selectHasCoPaymentExemption(hasExemption: boolean) {
    values.value.hasCoPaymentExemption = hasExemption
    nextStep()
}

function selectPrescriptionTransmission(transmissionType: string) {
    formModel.value.registrationPrescriptionTransmission = transmissionType
    nextStep()
}

function nextStep() {
    switch (step.value) {
        case "payType":
            switch (formModel.value.payType) {
                case "doctor":
                    step.value = "uploads"
                    break
                case "private_insurance":
                case "public_insurance":
                case "self":
                    step.value = "prescriptionTransmission"
                    break
            }
            break

        case "prescriptionTransmission":
            switch (formModel.value.payType) {
                case "private_insurance":
                    step.value = "privateCard"
                    break

                case "public_insurance":
                    step.value = "coPaymentExemption"
                    break

                default:
                    step.value = "uploads"
            }
            break

        case "privateCard":
            if (values.value.hasPrivateCard) {
                step.value = "uploadPrivateCard"
            } else {
                step.value = "uploads"
            }
            break

        case "uploadPrivateCard":
            step.value = "uploads"
            break

        case "coPaymentExemption":
            if (values.value.hasCoPaymentExemption) {
                step.value = "uploadCoPaymentExemption"
            } else {
                step.value = "uploads"
            }
            break

        case "uploadCoPaymentExemption":
            step.value = "uploads"
            break

        case "uploads":
            step.value = "infos"
            break
    }
}

function backToStep(_step: StepType) {
    // fall through steps / cases on purpose. list steps that come up first first in the switch statement,
    // so that going back to the first steps clears all other inputs too
    switch (_step) {
        case "payType":
            formModel.value.payType = undefined
            formModel.value.cardFront = undefined
            formModel.value.cardBack = undefined
            formModel.value.coPaymentExemption = undefined
        // eslint-disable-next-line no-fallthrough
        case "prescriptionTransmission":
            formModel.value.registrationPrescriptionTransmission = undefined
        // eslint-disable-next-line no-fallthrough
        case "privateCard":
            values.value.hasPrivateCard = undefined
        // eslint-disable-next-line no-fallthrough
        case "coPaymentExemption":
            values.value.hasCoPaymentExemption = undefined
    }
    step.value = _step
}

const isUploadsValid = computed(() => {
    console.log("---- validate uploads ----")
    if (formModel.value.payType === "public_insurance" && !formModel.value.reimbursementDeclaration) {
        console.log("validate uploads: missing reimbursementDeclaration")
        return false
    }

    if (
        formModel.value.payType !== "doctor" &&
        formModel.value.registrationPrescriptionTransmission === "upload" &&
        !formModel.value.prescription
    ) {
        console.log("validate uploads: missing prescription")
        return false
    }

    if (!formModel.value.idCardFront || !formModel.value.idCardBack) {
        console.log("validate uploads: missing idCardFront / idCardBack")
        return false
    }

    if (
        ["public_insurance", "doctor", "self"].includes(formModel.value.payType) &&
        (!formModel.value.cardFront || !formModel.value.cardBack)
    ) {
        console.log("validate uploads: missing cardFront / cardBack")
        return false
    }

    return true
})
/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
const invalidElements = ref<any>({})
const loading = ref(false)
const step = ref<StepType>("payType")
const wizardOptions: {
    privateCard: MultipleChoiceOption<boolean>[]
    coPaymentExemption: MultipleChoiceOption<boolean>[]
    payType: MultipleChoiceOption<string>[]
    prescriptionTransmission: MultipleChoiceOption<string>[]
} = {
    privateCard: [
        {
            name: "Ja",
            description: "Ich bin im Besitz einer Versichertenkarte",
            value: true,
        },
        {
            name: "Nein",
            description: "Ich besitze keine Versichertenkarte",
            value: false,
        },
    ],
    coPaymentExemption: [
        {
            name: "Ja",
            description: "Ich bin von Zuzahlungen befreit und habe eine schriftliche Bestätigung meiner Krankenkasse.",
            value: true,
        },
        {
            name: "Nein",
            description: "Ich bin nicht zuzahlungsbefreit.",
            value: false,
        },
    ],
    prescriptionTransmission: [
        {
            name: "Rezept-Upload",
            description: "Ich lade mein erstes Rezept selber hoch",
            value: "upload",
        },
        {
            name: "Rezept per Post",
            description: "Mein erstes Rezept kommt direkt per Post vom Arzt zu euch",
            value: "doctor/letter",
        },
        {
            name: "Digitales Rezept",
            description: "Mein erstes Rezept wird euch digital von meinem Arzt übertragen",
            value: "doctor/digital",
        },
    ],
    payType: [
        {
            name: "Selbstzahler (gesetzlich versichert)",
            description: "Du musst die Kosten deiner Therapie selbst tragen",
            value: "self",
        },
        {
            name: "Privatversichert",
            description: "Du bist bei einer privaten Krankenversicherung versichert",
            value: "private_insurance",
        },
        {
            name: "Kassenpatient (mit Kostenübernahme)",
            description: "Du bist im Besitz einer Kostenübernahme deiner Krankenkasse",
            value: "public_insurance",
        },
        {
            name: "Arzt / Ärztin",
            description: "Du bist Arzt / Ärztin und verschreibst Cannabis auf Rezept",
            value: "doctor",
        },
    ],
}
const values = ref<{
    hasPrivateCard: boolean | undefined
    hasCoPaymentExemption: boolean | undefined
}>({
    hasPrivateCard: undefined,
    hasCoPaymentExemption: undefined,
})
/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/
const config = normalizeModuleConfiguration({ apiBase: () => "/registration" })
const { item } = await useLoadEntity(undefined, config)
const registrationSuccess = ref(false)

/*─────────────────────────────────────┐
│   capture VfForm state               │
└─────────────────────────────────────*/
const formErrors = ref<FormErrors>({})
let formModel: Ref<FormModel>

function onError(error: Record<string, string[]>) {
    formErrors.value = error
    loading.value = false
}

function onModel(model: Ref<FormModel>) {
    console.log("formModel captured", model)
    formModel = model
}

async function onSave(event: EntityEvent) {
    loading.value = true

    // reformat birthdate from dd.mm.yyyy to yyyy-mm-dd
    if (event.entity.dateOfBirth) {
        const [day, month, year] = event.entity.dateOfBirth.split(".")
        event.entity.dateOfBirth = `${year}-${month}-${day}`
    }
}

async function onSaved() {
    registrationSuccess.value = true
    loading.value = false
    await nextTick()
    window.scrollTo(0, 0)
}
</script>

<template>
    <!-- <div v-if="formError && formError.errors.length > 0" class="alert alert-danger" role="alert">
        <div v-for="error in formError.errors">
            {{ error }}
        </div>
    </div> -->
    <div class="customer-area-header">
        <div class="container">
            <div class="customer-area-breadcrumb">Home / Registrierung</div>
            <div class="customer-area-heading">
                <h2>CANN24</h2>
                <h1>Registrierung</h1>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="pb-5"></div>

        <div v-if="registrationSuccess" class="my-4 pb-5">
            <h3>Registrierung erfolgreich</h3>

            Sobald du von uns freigeschaltet wurdest, kannst du dich einloggen.
        </div>
        <div v-else>
            <VfForm
                v-bind="{ item, config }"
                @error="error => onError(error)"
                @model="model => onModel(model)"
                @saved="onSaved"
                @save="onSave"
            >
                <div
                    v-if="formModel.payType"
                    class="wizard-button d-flex align-items-center pl-0 mt-0 mb-4"
                    @click="backToStep('payType')"
                >
                    <i class="fa fa-fw fa-chevron-left px-4"></i>
                    <div>
                        <b>{{ wizardOptionByValue("payType", formModel.payType).name }}</b>

                        <div class="small">
                            {{ wizardOptionByValue("payType", formModel.payType).description }}
                        </div>
                    </div>
                </div>

                <div
                    v-if="formModel.registrationPrescriptionTransmission"
                    class="wizard-button d-flex align-items-center pl-0 mt-0 mb-4"
                    @click="backToStep('prescriptionTransmission')"
                >
                    <i class="fa fa-fw fa-chevron-left px-4"></i>
                    <div>
                        <b>{{
                            wizardOptionByValue(
                                "prescriptionTransmission",
                                formModel.registrationPrescriptionTransmission,
                            ).name
                        }}</b>

                        <div class="small">
                            {{
                                wizardOptionByValue(
                                    "prescriptionTransmission",
                                    formModel.registrationPrescriptionTransmission,
                                ).description
                            }}
                        </div>
                    </div>
                </div>

                <div
                    v-if="values.hasPrivateCard !== undefined"
                    class="wizard-button d-flex align-items-center pl-0 mt-0 mb-4"
                    @click="backToStep('privateCard')"
                >
                    <i class="fa fa-fw fa-chevron-left px-4"></i>
                    <div>
                        <b>{{ wizardOptionByValue("privateCard", values.hasPrivateCard!).name }}</b>

                        <div class="small">
                            {{ wizardOptionByValue("privateCard", values.hasPrivateCard!).description }}
                        </div>
                    </div>
                </div>

                <div
                    v-if="values.hasCoPaymentExemption !== undefined"
                    class="wizard-button d-flex align-items-center pl-0 mt-0 mb-4"
                    @click="backToStep('coPaymentExemption')"
                >
                    <i class="fa fa-fw fa-chevron-left px-4"></i>
                    <div>
                        <b>{{ wizardOptionByValue("coPaymentExemption", values.hasCoPaymentExemption!).name }}</b>

                        <div class="small">
                            {{ wizardOptionByValue("coPaymentExemption", values.hasCoPaymentExemption!).description }}
                        </div>
                    </div>
                </div>

                <div
                    v-if="
                        values.hasCoPaymentExemption &&
                        formModel.coPaymentExemption &&
                        ['infos', 'uploads'].includes(step)
                    "
                    class="wizard-button d-flex align-items-center pl-0 mt-0 mb-4"
                    :class="{ 'has-error': formErrors.coPaymentExemption?.length > 0 }"
                    @click="backToStep('uploadCoPaymentExemption')"
                >
                    <i class="fa fa-fw fa-chevron-left px-4"></i>
                    <div>
                        <b>Zuzahlungsbefreiung</b>

                        <div class="small">
                            <i class="fa fa-fw fa-check"></i>
                            Hochgeladen
                        </div>
                    </div>
                </div>

                <div
                    v-if="
                        values.hasPrivateCard &&
                        formModel.cardFront &&
                        formModel.cardBack &&
                        ['infos', 'uploads'].includes(step)
                    "
                    class="wizard-button d-flex align-items-center pl-0 mt-0 mb-4"
                    :class="{
                        'has-error': formErrors.cardFront?.length > 0 || formErrors.cardBack?.length > 0,
                    }"
                    @click="backToStep('uploadPrivateCard')"
                >
                    <i class="fa fa-fw fa-chevron-left px-4"></i>
                    <div>
                        <b>Versichertenkarte</b>

                        <div class="small">
                            <i class="fa fa-fw fa-check"></i>
                            Vorderseite
                        </div>
                        <div class="small">
                            <i class="fa fa-fw fa-check"></i>
                            Rückseite
                        </div>
                    </div>
                </div>

                <div
                    v-if="step === 'infos'"
                    class="wizard-button d-flex align-items-center pl-0 mt-0 mb-4"
                    :class="{
                        'has-error':
                            formErrors.idCardFront?.length > 0 ||
                            formErrors.idCardBack?.length > 0 ||
                            formErrors.prescription?.length > 0 ||
                            formErrors.reimbursementDeclaration?.length > 0 ||
                            (formModel.payType !== 'private_insurance' && formErrors.cardFront?.length > 0) ||
                            formErrors.cardBack?.length > 0,
                    }"
                    @click="backToStep('uploads')"
                >
                    <i class="fa fa-fw fa-chevron-left px-4"></i>
                    <div>
                        <b>Uploads</b>

                        <div v-if="formModel.payType !== 'private_insurance'" class="small">
                            <i class="fa fa-fw fa-check"></i>
                            {{ formModel.payType === "doctor" ? "Arztausweis" : "Versichertenkarte" }}
                        </div>
                        <div class="small">
                            <i class="fa fa-fw fa-check"></i>
                            Personalausweis
                        </div>
                        <div v-if="formModel.registrationPrescriptionTransmission === 'upload'" class="small">
                            <i class="fa fa-fw fa-check"></i>
                            Rezept
                        </div>
                        <div v-if="formModel.payType === 'public_insurance'" class="small">
                            <i class="fa fa-fw fa-check"></i>
                            Kostenübernahmeerklärung
                        </div>
                    </div>
                </div>

                <div v-if="step === 'payType'">
                    Herzlich willkommen bei CANN24. Bitte wähle als Erstes die Option aus, die auf dich zutrifft:

                    <div
                        v-for="option in wizardOptions.payType"
                        :key="option.name"
                        class="wizard-button"
                        @click="selectPayType(option.value)"
                    >
                        <b>{{ option.name }}</b>

                        <div class="small">
                            {{ option.description }}
                        </div>
                    </div>
                </div>

                <div v-if="step === 'prescriptionTransmission'">
                    Wie kommt dein erstes Rezept zu uns?

                    <div
                        v-for="option in wizardOptions.prescriptionTransmission"
                        :key="option.name"
                        class="wizard-button"
                        @click="selectPrescriptionTransmission(option.value)"
                    >
                        <b>{{ option.name }}</b>

                        <div class="small">
                            {{ option.description }}
                        </div>
                    </div>
                </div>

                <div v-if="step === 'privateCard'">
                    Bist du im Besitz einer Versichertenkarte deiner privaten Krankenversicherung?

                    <div
                        v-for="option in wizardOptions.privateCard"
                        :key="option.name"
                        class="wizard-button"
                        @click="selectHasPrivateCard(option.value)"
                    >
                        <b>{{ option.name }}</b>

                        <div class="small">
                            {{ option.description }}
                        </div>
                    </div>
                </div>

                <div v-if="step === 'coPaymentExemption'">
                    Bist du von Zuzahlungen befreit?

                    <div
                        v-for="option in wizardOptions.coPaymentExemption"
                        :key="option.name"
                        class="wizard-button"
                        @click="selectHasCoPaymentExemption(option.value)"
                    >
                        <b>{{ option.name }}</b>

                        <div class="small">
                            {{ option.description }}
                        </div>
                    </div>
                </div>

                <div v-if="step === 'uploadPrivateCard'">
                    <b v-if="formModel.payType !== 'doctor'">Meine Versichertenkarte</b>
                    <div class="bg-primary-light px-4 py-3">
                        <div class="mb-3">
                            <p>Bitte lade die Karte deiner privaten Krankenversicherung hoch.</p>
                        </div>

                        <div class="row my-2 align-items-center">
                            <div class="col-md-8 text-muted">Vorderseite</div>
                            <div class="col-md-4 my-auto">
                                <label
                                    for="registration_form_cardFront"
                                    :class="
                                        'btn ' +
                                        (invalidElements.cardFront ? 'btn-danger' : 'btn-primary') +
                                        ' btn-block'
                                    "
                                >
                                    <span v-if="!formModel.cardFront">Upload</span>
                                    <i v-if="formModel.cardFront" class="fa fa-check"></i>
                                </label>
                            </div>
                        </div>

                        <div class="row my-2 align-items-center">
                            <div class="col-md-8 text-muted">Rückseite</div>
                            <div class="col-md-4 my-auto">
                                <label
                                    for="registration_form_cardBack"
                                    :class="
                                        'btn ' +
                                        (invalidElements.cardBack ? 'btn-danger' : 'btn-primary') +
                                        ' btn-block'
                                    "
                                >
                                    <span v-if="!formModel.cardBack">Upload</span>
                                    <i v-if="formModel.cardBack" class="fa fa-check"></i>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="d-lg-flex align-items-center justify-content-between mt-4">
                        <div class="text-muted small">
                            Dieser Upload hilft uns, deinen Versicherungsstatus zu verifizieren.
                        </div>
                        <div>
                            <button
                                type="button"
                                class="btn btn-primary"
                                :disabled="!formModel.cardBack || !formModel.cardFront"
                                @click="nextStep()"
                            >
                                Weiter
                            </button>
                        </div>
                    </div>
                </div>

                <div v-if="step === 'uploadCoPaymentExemption'">
                    <b>Zuzahlungsbefreiung</b>

                    <div class="bg-primary-light px-4 py-3">
                        <div class="row">
                            <div class="col-md-8 text-muted small">
                                Bitte lade die Bestätigung deiner Krankenkasse hoch, dass du von Zuzahlungen befreit
                                bist.
                            </div>
                            <div class="col-md-4 my-auto">
                                <label
                                    for="registration_form_coPaymentExemption"
                                    :class="
                                        'btn ' +
                                        (invalidElements.coPaymentExemption ? 'btn-danger' : 'btn-primary') +
                                        ' btn-block'
                                    "
                                >
                                    <span v-if="!formModel.coPaymentExemption">Upload</span>
                                    <i v-if="formModel.coPaymentExemption" class="fa fa-check"></i>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="d-lg-flex align-items-center justify-content-between mt-4">
                        <div class="text-muted small">
                            Dieser Upload hilft uns, deinen Versicherungsstatus zu verifizieren.
                        </div>
                        <div>
                            <button
                                type="button"
                                class="btn btn-primary"
                                :disabled="!formModel.coPaymentExemption"
                                @click="nextStep()"
                            >
                                Weiter
                            </button>
                        </div>
                    </div>
                </div>

                <div v-if="step === 'uploads'">
                    <div v-show="formModel.payType !== 'private_insurance'">
                        <b v-if="formModel.payType !== 'doctor'">Meine Versichertenkarte</b>
                        <b v-if="formModel.payType === 'doctor'">Arztausweis</b>

                        <div class="bg-primary-light px-4 py-3">
                            <p v-if="['self', 'public_insurance'].includes(formModel.payType)" class="mb-3">
                                Bitte lade die Karte deiner gesetzlichen Krankenversicherung hoch.
                            </p>

                            <p v-if="['doctor'].includes(formModel.payType)" class="mb-3">
                                Bitte lade deinen Arztausweis hoch.
                            </p>

                            <div class="row my-2 align-items-center">
                                <div class="col-md-8 text-muted">Vorderseite</div>
                                <div class="col-md-4 my-auto">
                                    <label
                                        for="registration_form_cardFront"
                                        :class="
                                            'btn ' +
                                            (invalidElements.cardFront ? 'btn-danger' : 'btn-primary') +
                                            ' btn-block'
                                        "
                                    >
                                        <span v-if="!formModel.cardFront">Upload</span>
                                        <i v-if="formModel.cardFront" class="fa fa-check"></i>
                                    </label>
                                </div>
                            </div>

                            <div class="row my-2 align-items-center">
                                <div class="col-md-8 text-muted">Rückseite</div>
                                <div class="col-md-4 my-auto">
                                    <label
                                        for="registration_form_cardBack"
                                        :class="
                                            'btn ' +
                                            (invalidElements.cardBack ? 'btn-danger' : 'btn-primary') +
                                            ' btn-block'
                                        "
                                    >
                                        <span v-if="!formModel.cardBack">Upload</span>
                                        <i v-if="formModel.cardBack" class="fa fa-check"></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="formModel.payType === 'self'" class="text-muted small">
                            Dieser Upload hilft uns, deinen Status als Selbstzahler zu verifizieren.
                        </div>
                        <div
                            v-if="['private_insurance', 'public_insurance'].includes(formModel.payType)"
                            class="text-muted small"
                        >
                            Dieser Upload hilft uns, deinen Versicherungsstatus zu verifizieren.
                        </div>
                        <div v-if="formModel.payType === 'doctor'" class="text-muted small">
                            Dieser Upload hilft uns, deinen Status als Arzt zu verifizieren.
                        </div>
                    </div>

                    <div class="mt-5">
                        <b>Mein Personalausweis</b>

                        <div class="bg-primary-light px-4 py-3">
                            <p class="mb-3">Bitte lade deinen Personalausweis hoch</p>

                            <div class="row my-2 align-items-center">
                                <div class="col-md-8 text-muted">Vorderseite</div>
                                <div class="col-md-4 my-auto">
                                    <label
                                        for="registration_form_idCardFront"
                                        :class="
                                            'btn ' +
                                            (invalidElements.idCardFront ? 'btn-danger' : 'btn-primary') +
                                            ' btn-block'
                                        "
                                    >
                                        <span v-if="!formModel.idCardFront">Upload</span>
                                        <i v-if="formModel.idCardFront" class="fa fa-check"></i>
                                    </label>
                                </div>
                            </div>

                            <div class="row my-2 align-items-center">
                                <div class="col-md-8 text-muted">Rückseite</div>
                                <div class="col-md-4 my-auto">
                                    <label
                                        for="registration_form_idCardBack"
                                        :class="
                                            'btn ' +
                                            (invalidElements.idCardBack ? 'btn-danger' : 'btn-primary') +
                                            ' btn-block'
                                        "
                                    >
                                        <span v-if="!formModel.idCardBack">Upload</span>
                                        <i v-if="formModel.idCardBack" class="fa fa-check"></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="
                            formModel.payType !== 'doctor' &&
                            formModel.registrationPrescriptionTransmission === 'upload'
                        "
                        class="mt-5"
                    >
                        <b>Rezept</b>

                        <div class="bg-primary-light px-4 py-3">
                            <div class="row">
                                <div class="col-md-8 text-muted small">
                                    Bitte lade ein Rezept hoch. Es kann sich dabei auch um ein altes, bereits
                                    eingelöstes Rezept handeln.
                                </div>
                                <div class="col-md-4 my-auto">
                                    <label
                                        for="registration_form_prescription"
                                        :class="
                                            'btn ' +
                                            (invalidElements.prescription ? 'btn-danger' : 'btn-primary') +
                                            ' btn-block'
                                        "
                                    >
                                        <span v-if="!formModel.prescription">Upload</span>
                                        <i v-if="formModel.prescription" class="fa fa-check"></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="formModel.payType === 'public_insurance'" class="mt-5">
                        <b>Kostenübernahmeerklärung</b>

                        <div class="bg-primary-light px-4 py-3">
                            <div class="row">
                                <div class="col-md-8 text-muted small">
                                    Bitte lade die Kostenübernahmeerklärung deiner Krankenkasse hoch.
                                </div>
                                <div class="col-md-4 my-auto">
                                    <label
                                        for="registration_form_reimbursementDeclaration"
                                        :class="
                                            'btn ' +
                                            (invalidElements.reimbursementDeclaration ? 'btn-danger' : 'btn-primary') +
                                            ' btn-block'
                                        "
                                    >
                                        <span v-if="!formModel.reimbursementDeclaration">Upload</span>
                                        <i v-if="formModel.reimbursementDeclaration" class="fa fa-check"></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-right mt-4">
                        <button type="button" class="btn btn-primary" :disabled="!isUploadsValid" @click="nextStep()">
                            Weiter
                        </button>
                    </div>
                </div>

                <div v-if="step === 'infos'">
                    <div class="row">
                        <div class="col-md-12">
                            <VfFormRow name="gender"></VfFormRow>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <VfFormRow name="firstName"></VfFormRow>
                        </div>
                        <div class="col-md-6">
                            <VfFormRow name="lastName"></VfFormRow>
                        </div>
                    </div>

                    <h2 v-if="formModel.payType === 'doctor'" class="mb-3">Praxisadresse</h2>

                    <VfFormRow name="street"></VfFormRow>

                    <div class="row">
                        <div class="col-md-3">
                            <VfFormRow name="zip"></VfFormRow>
                        </div>
                        <div class="col-md-9">
                            <VfFormRow name="city"></VfFormRow>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <VfFormRow name="deliveryNote"></VfFormRow>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <VfFormRow name="phone"></VfFormRow>
                        </div>
                    </div>

                    <VfFormGroup name="email">
                        <div class="row">
                            <div class="col-md-6">
                                <VfFormRow name="first" @copy.prevent @paste.prevent></VfFormRow>
                            </div>
                            <div class="col-md-6">
                                <VfFormRow name="second" @copy.prevent @paste.prevent></VfFormRow>
                            </div>
                        </div>
                    </VfFormGroup>

                    <div v-if="formModel.payType === 'doctor'" class="mb-5"></div>

                    <VfFormGroup name="plainPassword">
                        <div class="row">
                            <div class="col-md-6">
                                <VfFormRow name="first"></VfFormRow>
                            </div>
                            <div class="col-md-6">
                                <VfFormRow name="second"></VfFormRow>
                            </div>
                        </div>
                    </VfFormGroup>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group mb-0">
                                <label class="col-form-label required" for="dateOfBirth">Geburtsdatum</label>
                                <div class="flex-grow-1">
                                    <Cleave
                                        v-model="formModel.dateOfBirth"
                                        class="form-control"
                                        placeholder="TT.MM.JJJJ"
                                        :options="{
                                            date: true,
                                            datePattern: ['d', 'm', 'Y'],
                                            delimiter: '.',
                                        }"
                                        :raw="false"
                                    ></Cleave>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-for="message of formErrors.dateOfBirth" :key="message" class="alert alert-danger pre-line">
                        {{ message }}
                    </div>
                    <VfFormRendered name="dateOfBirth"></VfFormRendered>

                    <div v-if="formModel.payType === 'doctor'" class="row">
                        <div class="col-md-6">
                            <VfFormRow name="doctorSpecialization"></VfFormRow>
                        </div>
                    </div>

                    <div
                        class="d-flex justify-content-between flex-column flex-lg-row align-content-center mt-5 mt-md-4"
                    >
                        <div>
                            Dein Konto wird von uns nach erfolgter Prüfung deiner Daten freigeschaltet. Hierüber
                            informieren wir dich per E-Mail. Erst danach kannst du dich mit deinen Zugangsdaten
                            einloggen.
                        </div>
                        <div class="text-right pl-4 mt-3 mt-md-0" style="min-width: 200px">
                            <button v-if="!loading" type="submit" class="btn btn-primary btn-lg btn-block">
                                Registrieren
                            </button>
                            <button v-if="loading" type="button" class="btn btn-primary btn-lg btn-block" disabled>
                                <i class="fa fa-fw fa-circle-notch fa-spin"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="d-none">
                    <input
                        id="registration_form_cardFront"
                        type="file"
                        name="registration_form[cardFront]"
                        accept="image/jpeg,application/pdf"
                        @change="elementChanged('cardFront', $event)"
                        @invalid="elementInvalid('cardFront')"
                    />
                    <input
                        id="registration_form_cardBack"
                        type="file"
                        name="registration_form[cardBack]"
                        accept="image/jpeg,application/pdf"
                        @change="elementChanged('cardBack', $event)"
                        @invalid="elementInvalid('cardBack')"
                    />
                    <input
                        id="registration_form_idCardFront"
                        type="file"
                        name="registration_form[idCardFront]"
                        required
                        accept="image/jpeg,application/pdf"
                        @change="elementChanged('idCardFront', $event)"
                        @invalid="elementInvalid('idCardFront')"
                    />
                    <input
                        id="registration_form_idCardBack"
                        type="file"
                        name="registration_form[idCardBack]"
                        required
                        accept="image/jpeg,application/pdf"
                        @change="elementChanged('idCardBack', $event)"
                        @invalid="elementInvalid('idCardBack')"
                    />
                    <input
                        id="registration_form_coPaymentExemption"
                        type="file"
                        name="registration_form[coPaymentExemption]"
                        accept="image/jpeg,application/pdf"
                        @change="elementChanged('coPaymentExemption', $event)"
                        @invalid="elementInvalid('coPaymentExemption')"
                    />
                    <input
                        id="registration_form_prescription"
                        type="file"
                        name="registration_form[prescription]"
                        accept="image/jpeg,application/pdf"
                        @change="elementChanged('prescription', $event)"
                        @invalid="elementInvalid('prescription')"
                    />
                    <input
                        id="registration_form_reimbursementDeclaration"
                        type="file"
                        name="registration_form[reimbursementDeclaration]"
                        accept="image/jpeg,application/pdf"
                        @change="elementChanged('reimbursementDeclaration', $event)"
                        @invalid="elementInvalid('reimbursementDeclaration')"
                    />
                    <input ref="formFields.payType" type="hidden" />
                </div>

                <div class="col-xl-5"></div>
            </VfForm>
        </div>
    </div>
</template>
