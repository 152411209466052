<script setup lang="ts">
import type { CustomerUpload } from "@/model/backend/customer-upload"
import type { Order } from "@/model/backend/order"
import { useShopAppConfig } from "@/shop/composables"
import { useShopDialogs } from "@/shop/composables/useShopDialogs"
import { $currency, $date, $shortdatetime, useApp, useHttpClient, useHttpGet, useSimpleUpload } from "@/vf"
import type { AxiosError } from "axios"
import { ref } from "vue"
import MyAccountCellCertificates from "./MyAccountCellCertificates.vue"
import MyAccountCellInvoice from "./MyAccountCellInvoice.vue"
import MyAccountCellStateCompleted from "./MyAccountCellStateCompleted.vue"
import MyAccountCellStatePayment from "./MyAccountCellStatePayment.vue"
import MyAccountCellStatePrescription from "./MyAccountCellStatePrescription.vue"
import MyAccountCellUploads from "./MyAccountCellUploads.vue"
import MyAccountOrderStateTimeline from "./MyAccountOrderStateTimeline.vue"
import type { Event } from "@/model/backend/event"
import type { EventRegistration } from "@/model/backend/event-registration"
import MyAccountEventRegistration from "./MyAccountEventRegistration.vue"
import type { OrderState } from "@/model/order-state"

interface MyAccountResponse {
    lastPrescription: { createdAt: string }
    lastCard: { createdAt: string }
    orders: Order[]
    address: {
        firstName: string
        lastName: string
        street: string
        zip: string
        city: string
        phone: string
        email: string
        dateOfBirth: string
        deliveryNote: string
    }
    moreStatusUpdates: boolean
    payType: string
    balanceAccountBalance: number
    bonusPoints: number
    lastCardFront: CustomerUpload
    lastCardBack: CustomerUpload
    eventRegistration?: {
        event: Event
        registration: EventRegistration
    }
}

async function upload(type: string): Promise<void> {
    uploadingType.value = type
    await simpleUpload.asyncUpload("my-account/upload/" + type, "image/*,application/pdf")
    await refresh()
}

async function saveAddress(): Promise<void> {
    if (!data.value) {
        return
    }

    saving.value = true
    await http.post("my-account/my-address", {
        email: data.value.address.email,
        phone: data.value.address.phone,
        moreStatusUpdates: data.value.moreStatusUpdates,
        deliveryNote: data.value.address.deliveryNote,
    })
    await refresh()
    saving.value = false
    saved.value = true
    setTimeout(() => (saved.value = false), 3000)
}

async function savePassword(): Promise<void> {
    saving.value = true
    try {
        await http.post("my-account/change-password", { plainPassword: newPassword.value })
        openPasswordChange.value = false
    } catch (err) {
        const e = err as AxiosError<{ errors: string[] }>
        changePasswordError.value = e.response?.data.errors[0]
    }
    saving.value = false
}

async function cancelOrder(order: Order): Promise<void> {
    if (
        !(await dialogs.confirm(
            "Bestellung stornieren?",
            "Soll Deine Bestellung wirklich storniert werden? Die bestellten Artikel sind dann nicht mehr für Dich reserviert.",
        ))
    ) {
        return
    }
    try {
        await http.post(`/order/${order.id}/cancel-order`, {})
        await refresh()
    } catch {
        await dialogs.alert("Fehler", "Bestellung konnte nicht storniert werden.")
    }
}

/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const app = useApp()
const simpleUpload = useSimpleUpload()
const http = useHttpClient()
const dialogs = useShopDialogs()
const { appConfig } = useShopAppConfig()
/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
const uploadingType = ref<string>()
const { data, refresh } = await useHttpGet<MyAccountResponse>("my-account/")

const saving = ref<boolean>(false)
const saved = ref<boolean>(false)
const openPasswordChange = ref<boolean>(false)
const newPassword = ref<{
    first: string
    second: string
}>({ first: "", second: "" })
const changePasswordError = ref<string>()
</script>

<template>
    <div class="customer-area-header">
        <div class="container">
            <div class="customer-area-breadcrumb">Home / Kundenbereich</div>

            <div class="customer-area-heading">
                <h2>CANN24</h2>
                <h1>Mein Kundenbereich</h1>
            </div>

            <div class="customer-area-card">
                <div class="customer-area-card-inner">
                    <div class="customer-area-card-logo"></div>

                    <span>Meine Patienten ID</span>
                    <b>{{ appConfig.user.customerNumber || "nicht verfügbar" }}</b>
                </div>
                <div class="customer-area-card-decal"></div>
            </div>
        </div>
    </div>

    <div class="container my-account">
        <div class="pt-5"></div>
        <div class="row">
            <div class="col-md-6">
                <MyAccountEventRegistration
                    v-if="data?.eventRegistration"
                    v-bind="data.eventRegistration"
                    @reload="refresh"
                />

                <div class="py-4">
                    <b>Kontoeinstellungen</b>

                    <p class="mt-3">
                        Um Deinen Namen oder Deine Adresse zu ändern setze Dich bitte mit uns in Verbindung.
                    </p>
                </div>
                <form @submit.prevent="saveAddress()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group mb-4">
                                <label for="firstName">Vorname</label>
                                <input
                                    id="firstName"
                                    v-model="data!.address.firstName"
                                    type="text"
                                    class="form-control"
                                    placeholder="Vorname"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-4">
                                <label for="lastName">Nachname</label>
                                <input
                                    id="lastName"
                                    v-model="data!.address.lastName"
                                    type="text"
                                    class="form-control"
                                    placeholder="Nachname"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-4">
                                <label for="street">Straße</label>
                                <input
                                    id="street"
                                    v-model="data!.address.street"
                                    type="text"
                                    class="form-control"
                                    placeholder="Straße"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="form-group mb-4">
                                        <label for="zip">PLZ</label>
                                        <input
                                            id="zip"
                                            v-model="data!.address.zip"
                                            type="text"
                                            class="form-control"
                                            placeholder="PLZ"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="form-group mb-4">
                                        <label for="city">Stadt</label>
                                        <input
                                            id="city"
                                            v-model="data!.address.city"
                                            type="text"
                                            class="form-control"
                                            placeholder="Stadt"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-4">
                                <label for="phone">Telefon</label>
                                <input
                                    id="phone"
                                    v-model="data!.address.phone"
                                    type="text"
                                    class="form-control"
                                    placeholder="Telefon"
                                />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-4">
                                <label for="email">E-Mail</label>
                                <input
                                    id="email"
                                    v-model="data!.address.email"
                                    type="email"
                                    class="form-control"
                                    placeholder="E-Mail"
                                />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-4 mb-0">
                                <label for="dateOfBirth">Geburtsdatum</label>
                                <input
                                    id="dateOfBirth"
                                    v-model="data!.address.dateOfBirth"
                                    type="date"
                                    class="form-control"
                                    placeholder="Geburtsdatum"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-4 mb-0">
                                <label for="deliveryNote">Lieferhinweis</label>
                                <input
                                    id="deliveryNote"
                                    v-model="data!.address.deliveryNote"
                                    type="text"
                                    class="form-control"
                                    placeholder="Lieferhinweis"
                                />
                            </div>
                        </div>
                        <div v-show="data!.payType !== 'doctor'" class="col-md-12">
                            <div class="checkbox">
                                <label>
                                    <input v-model="data!.moreStatusUpdates" type="checkbox" />

                                    Ich möchte über alle Status-Updates meiner Bestellung per Mail informiert werden
                                </label>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <button type="submit" class="btn btn-primary" :disabled="saving">
                                Speichern
                                <i v-if="saved" class="fas fa-check"></i>
                            </button>
                        </div>
                    </div>
                </form>

                <!--                <div class="py-4">-->
                <!--                    <b>Zahlungsmittel</b>-->
                <!--                </div>-->

                <!--                <label class="d-block">-->
                <!--                    <input type="radio" name="test">-->
                <!--                    <span class="custom-radio"></span>-->

                <!--                    Bankeinzug-->
                <!--                </label>-->
                <!--                <label class="d-block">-->
                <!--                    <input type="radio" name="test">-->
                <!--                    <span class="custom-radio"></span>-->

                <!--                    PayPal-->
                <!--                </label>-->
                <!--                <label class="d-block">-->
                <!--                    <input type="radio" name="test">-->
                <!--                    <span class="custom-radio"></span>-->

                <!--                    Klarna-->
                <!--                </label>-->
            </div>

            <div class="col-md-6 pt-5">
                <button class="btn btn-warning" @click="app.logout()">Logout</button>

                <div
                    v-if="appConfig.user.payType !== 'doctor'"
                    class="my-4 background-field d-flex justify-content-around align-items-center"
                >
                    <div class="text-center">
                        <h3>Guthaben</h3>
                        <h2>{{ $currency(data?.balanceAccountBalance ?? 0) }}</h2>
                    </div>
                    <!--                    <div class="text-center">-->
                    <!--                        <h3>Bonuspunkte</h3>-->
                    <!--                        <h2>{{ data?.bonusPoints ?? 0 }}</h2>-->
                    <!--                    </div>-->
                </div>

                <div class="mt-3 mb-1">
                    <b>Passwort</b>
                </div>
                <div class="background-field">
                    <div class="d-flex justify-content-between align-items-center">
                        <div style="font-size: 0.6rem"><i v-for="i in 10" :key="i" class="fas fa-circle pr-1"></i></div>
                        <button class="btn btn-primary" @click="openPasswordChange = !openPasswordChange">
                            Bearbeiten
                        </button>
                    </div>
                    <div v-show="openPasswordChange" class="mt-5">
                        <div v-if="changePasswordError" class="alert alert-danger">
                            {{ changePasswordError }}
                        </div>
                        <form @submit.prevent="savePassword()">
                            <div class="form-group mb-4">
                                <label for="newpass1">Neues Passwort</label>
                                <input id="newpass1" v-model="newPassword.first" type="password" class="form-control" />
                            </div>
                            <div class="form-group mb-4">
                                <label for="newpass2">Passwort Wiederholen</label>
                                <input
                                    id="newpass2"
                                    v-model="newPassword.second"
                                    type="password"
                                    class="form-control"
                                />
                            </div>
                            <div class="text-right">
                                <button type="submit" class="btn btn-primary" :disabled="saving">Speichern</button>
                            </div>
                        </form>
                    </div>
                </div>

                <div v-if="data?.payType !== 'doctor'" class="mt-4 mb-1">
                    <b>Meine Versichertenkarte</b>

                    <div class="bg-primary-light px-4 py-3">
                        <p class="mb-3">Erforderlich bei Privat- und gesetzlich Krankenversicherten</p>

                        <div class="d-flex justify-content-between my-2 align-items-center">
                            <div class>
                                Vorderseite
                                <div class="small text-muted">
                                    Letzter Upload:
                                    <span v-if="data!.lastCardFront">
                                        {{ $shortdatetime(data!.lastCardFront.createdAt) }}
                                    </span>
                                    <span v-else>-</span>
                                </div>
                            </div>
                            <div class="text-right">
                                <button class="btn btn-primary" @click="upload('card_front')">
                                    <span v-if="uploadingType === 'card_front' && simpleUpload.uploadStatus.running">
                                        <i class="fa fa-fw fa-circle-notch fa-spin"></i>
                                    </span>
                                    <span v-else>Upload</span>
                                </button>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between my-2 align-items-center">
                            <div class>
                                Rückseite
                                <div class="small text-muted">
                                    Letzter Upload:
                                    <span v-if="data!.lastCardBack">
                                        {{ $shortdatetime(data!.lastCardBack.createdAt) }}
                                    </span>
                                    <span v-else>-</span>
                                </div>
                            </div>
                            <div class="text-right">
                                <button class="btn btn-primary" @click="upload('card_back')">
                                    <span v-if="uploadingType === 'card_back' && simpleUpload.uploadStatus.running">
                                        <i class="fa fa-fw fa-circle-notch fa-spin"></i>
                                    </span>
                                    <span v-else> Upload </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="data!.payType !== 'doctor'">
            <h2 class="mt-5">Übersicht</h2>
            <h1>Meine Bestellungen</h1>

            <table class="table">
                <tbody>
                    <tr>
                        <th>Bestellung</th>
                        <th>Datum</th>
                        <th>Summe</th>
                        <th class="d-none d-md-table-cell text-center">Rechnung</th>
                        <th class="d-none d-md-table-cell text-center">Rezept</th>
                        <th class="d-none d-md-table-cell text-center">Zertifikat</th>
                        <th class="d-none d-md-table-cell text-center">Bezahlt</th>
                        <th class="d-none d-md-table-cell text-center">Rezept geprüft</th>
                        <th class="d-none d-md-table-cell text-center">Verschickt / Abholbereit</th>
                        <th class="d-none d-md-table-cell text-center">Bestellung stornieren</th>
                    </tr>
                    <template v-for="order in data!.orders" :key="order.id">
                        <tr>
                            <td>
                                <RouterLink :to="{ name: 'confirmation-order', params: { order: order.id } }">
                                    {{ order.orderNumber }}
                                </RouterLink>
                            </td>
                            <td>{{ $date(order.createdAt) }}</td>
                            <td class="text-md-right">{{ $currency(order.totalPrice) }}</td>
                            <td
                                v-if="(['cancelled', 'rejected'] as OrderState[]).includes(order.state!)"
                                class="text-center d-none d-md-table-cell text-muted"
                                colspan="7"
                            >
                                Bestellung storniert
                            </td>
                            <template v-if="!['cancelled', 'rejected'].includes(order.state!)">
                                <td class="text-center d-none d-md-table-cell">
                                    <MyAccountCellInvoice v-bind="{ order }" />
                                </td>
                                <td class="text-center d-none d-md-table-cell">
                                    <MyAccountCellUploads v-bind="{ order }" @uploaded="refresh" />
                                </td>
                                <td class="text-center d-none d-md-table-cell">
                                    <MyAccountCellCertificates v-bind="{ order }" />
                                </td>
                                <td class="text-center d-none d-md-table-cell">
                                    <MyAccountCellStatePayment v-bind="{ order }" />
                                </td>
                                <td class="text-center d-none d-md-table-cell">
                                    <MyAccountCellStatePrescription v-bind="{ order }" />
                                </td>
                                <td class="text-center d-none d-md-table-cell">
                                    <MyAccountCellStateCompleted v-bind="{ order }" />
                                </td>
                                <td class="text-center d-none d-md-table-cell">
                                    <button
                                        v-if="order.isUserCancelable"
                                        class="btn btn-primary"
                                        @click="cancelOrder(order)"
                                    >
                                        Storno
                                    </button>
                                </td>
                            </template>
                        </tr>
                        <tr class="d-md-none">
                            <td
                                v-if="(['cancelled', 'rejected'] as OrderState[]).includes(order.state!)"
                                colspan="3"
                                class="border-0 pb-5 pt-5 text-muted text-center"
                            >
                                Bestellung storniert
                            </td>
                            <td v-else-if="order.state !== 'cancelled'" colspan="3" class="border-0 pb-5 pt-0">
                                <div class="row my-2">
                                    <div class="col-8">Rechnung</div>
                                    <div class="col-4 text-center">
                                        <MyAccountCellInvoice v-bind="{ order }" />
                                    </div>
                                </div>

                                <div class="row my-2">
                                    <div class="col-8">Rezept</div>
                                    <div class="col-4 text-center">
                                        <MyAccountCellUploads v-bind="{ order }" />
                                    </div>
                                </div>

                                <div class="row my-2">
                                    <div class="col-8">Zertifikat(e)</div>
                                    <div class="col-4 text-center">
                                        <MyAccountCellCertificates v-bind="{ order }" />
                                    </div>
                                </div>

                                <h3 class="mt-4 mb-3">Status meiner Bestellung</h3>
                                <MyAccountOrderStateTimeline v-bind="{ order }" />

                                <div v-if="order.isUserCancelable" class="text-center mt-3">
                                    <button class="btn btn-primary" @click="cancelOrder(order)">
                                        Bestellung stornieren
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </template>

                    <template v-if="!data!.orders?.length">
                        <tr>
                            <td colspan="10" class="text-muted text-center py-3">
                                Hier kannst Du später Deine Bestellungen einsehen.
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </template>
    </div>
</template>
